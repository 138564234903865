import "./styles.css";
import { useState, useEffect } from "react";
import sub1 from "../../../img/subscriptions/1.jpg";
// import sub2 from "../../../img/subscriptions/2.jpg";
// import sub3 from "../../../img/subscriptions/3.jpg";
import sub4 from "../../../img/subscriptions/4.jpg";
import sub5 from "../../../img/subscriptions/5.jpg";
import sub7 from "../../../img/subscriptions/7.jpg";
import sub9 from "../../../img/subscriptions/9.jpg";
import sub10 from "../../../img/subscriptions/10.jpg";
import sub13 from "../../../img/subscriptions/13.jpg";
import sub16 from "../../../img/subscriptions/16.jpg";
import sub17 from "../../../img/subscriptions/17.jpg";
import sub19 from "../../../img/subscriptions/19.jpg";
import sub21 from "../../../img/subscriptions/21.jpg";
import sub25 from "../../../img/subscriptions/25.jpg";
import React from "react";

const SubscriptionsHome = () => {
  const subscriptions = [
    {
      id: 1,
      name: "Game Pass Ultimate на 1 месяц + 1 бонусный",
      price: "1400 ₽",
      img: sub1,
    },
    {
      id: 4,
      name: "Game Pass Ultimate на 4 месяца + 1 бонусный",
      price: "3190 ₽",
      img: sub4,
    },
    {
      id: 7,
      name: "Game Pass Ultimate на 7 месяцев + 1 бонусный",
      price: "4590 ₽",
      img: sub7,
    },
    {
      id: 10,
      name: "Game Pass Ultimate на 10 месяцев + 1 бонусный",
      price: "6090₽",
      img: sub10,
    },

    {
      id: 13,
      name: "Game Pass Ultimate на 13 месяцев + 1 бонусный",
      price: "7590 ₽",
      img: sub13,
    },

    {
      id: 16,
      name: "Game Pass Ultimate на 16 месяцев + 1 бонусный",
      price: "9090 ₽",
      img: sub16,
    },

    {
      id: 19,
      name: "Game Pass Ultimate на 19 месяцев + 1 бонусный",
      price: "10590 ₽",
      img: sub19,
    },
  ];
  useEffect(() => {}, []);

  return (
    <>
      <div className="gpoup-information__text" style={{ margin: "20px" }}>
        Подписки Game Pass Ultimate
      </div>
      <div className="subscriptions">
        {subscriptions.map((item) => (
          <div className="subscriptions__item">
            <img
              className="subscriptions__item-img"
              src={item.img}
              alt="Подписка"
            />
            <div className="subscriptions__item-name">{item.name}</div>
            <div className="subscriptions__item-price">{item.price}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default SubscriptionsHome;
